import request from '@/utils/request'

const dashboard_statisticApi = {
  dashboard_statistic_list: '/shop/dashboard_statistic/',
  order_statistic_list: '/shop/order_statistic/'
}

/**
 * 列表
 */
export function dashboard_statistic_list () {
  return request({
    url: dashboard_statisticApi.dashboard_statistic_list,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

/**
 * 列表
 */
export function order_statistic_list (parameter) {
  return request({
    url: dashboard_statisticApi.order_statistic_list,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}
