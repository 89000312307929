<template>
  <div id="dashboard-wrap">
    <!--头部数据-->
    <a-row :gutter="12">
      <a-col :sm="24" :md="12" :xl="8" :style="{ marginBottom: '24px' }">
        <chart-card :loading="loading">
          <template slot="title">
            <span>销售额</span>
          </template>
          <template slot="action">
            <a-button style="border: 1px solid #FF3311; color: #FF3311;" size="small">今日</a-button>
          </template>
          <template slot="total">
            <span>{{ '￥' + (dataAll.today_order_total_actual_paid / 100).toFixed(2) | numberFormat }}</span>
          </template>
          <div>
            <span style="padding-right: 25px;">昨日 {{ (dataAll.yesterday_order_total_actual_paid / 100).toFixed(2) | numberFormat }}</span>
            <trend :flag="QOQ(dataAll.today_order_total_actual_paid, dataAll.yesterday_order_total_actual_paid) > 0 ? 'up':'down'">
              <span slot="term">日环比</span>
              {{ QOQ(dataAll.today_order_total_actual_paid, dataAll.yesterday_order_total_actual_paid) }}%
            </trend>
          </div>
          <template slot="footer">
            <a-row type="flex" justify="space-between" align="bottom">
              <a-col :span="4">
                <span style="color: #909399">本月销售额</span>
              </a-col>
              <a-col :span="4" style="text-align: right">
                <span>
                  ￥ {{ (dataAll.this_month_order_total_actual_paid / 100 ).toFixed(2) | numberFormat }}
                </span>
              </a-col>
            </a-row>
          </template>
        </chart-card>
      </a-col>
      <a-col :sm="24" :md="12" :xl="8" :style="{ marginBottom: '24px' }">
        <chart-card :loading="loading" :total="'' + dataAll.today_order_total_amount">
          <span slot="title">订单量</span>
          <a-button slot="action" style="border: 1px solid #FF3311; color: #FF3311;" size="small">今日</a-button>
          <div>
            <span style="padding-right: 25px;">昨日 {{ dataAll.yesterday_order_total_amount | numberFormat }}</span>
            <trend :flag="QOQ(dataAll.today_order_total_amount, dataAll.yesterday_order_total_amount) > 0 ? 'up':'down'">
              <span slot="term">日环比</span>
              {{ QOQ(dataAll.today_order_total_amount, dataAll.yesterday_order_total_amount) }}%
            </trend>
          </div>
          <template slot="total">
            <span>{{ dataAll.today_order_total_amount | numberFormat }}</span>
          </template>
          <template slot="footer">
            <a-row type="flex" justify="space-between" align="bottom">
              <a-col :span="4">
                <span style="color: #909399">本月订单量</span>
              </a-col>
              <a-col :span="4" style="text-align: right">
                <span>
                  {{ dataAll.this_month_order_total_amount | numberFormat }} 单
                </span>
              </a-col>
            </a-row>
          </template>
        </chart-card>
      </a-col>
      <a-col :sm="24" :md="12" :xl="8" :style="{ marginBottom: '24px' }">
        <chart-card :loading="loading" :total="'' + dataAll.today_user_count">
          <span slot="title">新增用户</span>
          <a-button slot="action" style="border: 1px solid #FF3311; color: #FF3311;" size="small">今日</a-button>
          <div>
            <span style="padding-right: 25px;">昨日 {{ dataAll.yesterday_user_count | numberFormat }}</span>
            <trend :flag="QOQ(dataAll.today_user_count, dataAll.yesterday_user_count) > 0 ? 'up':'down'">
              <span slot="term">日环比</span>
              {{ QOQ(dataAll.today_user_count, dataAll.yesterday_user_count) }}%
            </trend>
          </div>
          <template slot="total">
            <span>{{ dataAll.today_user_count | numberFormat }}</span>
          </template>
          <template slot="footer">
            <a-row type="flex" justify="space-between" align="bottom">
              <a-col :span="4">
                <span style="color: #909399">本月新增用户</span>
              </a-col>
              <a-col :span="4" style="text-align: right">
                <span>
                  {{ dataAll.this_month_user_count | numberFormat }} 人
                </span>
              </a-col>
            </a-row>
          </template>
        </chart-card>
      </a-col>
      <a-col :sm="24" :md="12" :xl="8" :style="{ marginBottom: '24px' }" v-if="false">
        <chart-card :loading="loading" title="总出区货值" :total="'¥' + gate_sum || 8846">
          <a-tooltip title="指标说明" slot="action">
            <a-icon type="info-circle-o" />
          </a-tooltip>
          <div>
            <mini-area />
          </div>
          <template slot="footer">日均出区货值<span> {{ '¥1234' | NumberFormat }}</span></template>
        </chart-card>
      </a-col>
      <a-col :sm="24" :md="12" :xl="8" :style="{ marginBottom: '24px' }" v-if="false">
        <chart-card :loading="loading" title="订单数" :total="order_count">
          <a-tooltip title="指标说明" slot="action">
            <a-icon type="info-circle-o" />
          </a-tooltip>
          <div>
            <mini-bar />
          </div>
          <template slot="footer">转化率 <span>60%</span></template>
        </chart-card>
      </a-col>
      <a-col :sm="24" :md="12" :xl="6" :style="{ marginBottom: '24px' }" v-if="false">
        <chart-card :loading="loading" title="运营活动效果" total="78%">
          <a-tooltip title="指标说明" slot="action">
            <a-icon type="info-circle-o" />
          </a-tooltip>
          <div>
            <mini-progress color="rgb(19, 194, 194)" :target="80" :percentage="78" height="8px" />
          </div>
          <template slot="footer">
            <trend flag="down" style="margin-right: 16px;">
              <span slot="term">同周比</span>
              12%
            </trend>
            <trend flag="up">
              <span slot="term">日环比</span>
              80%
            </trend>
          </template>
        </chart-card>
      </a-col>
    </a-row>
    <!--预警信息-->
    <div v-if="true" class="antd-pro-pages-dashboard-analysis-twoColLayout" :class="!isMobile && 'desktop'" :style="{padding: '0', marginBottom: '24px'}">
      <a-row v-if="false" :gutter="24" type="flex" :style="{ marginTop: '24px' }">
        <a-col :xl="24" :lg="24" :md="24" :sm="24" :xs="24">
          <a-card :loading="loading" :bordered="false" title="预警信息" :style="{ height: '100%' }">
            <a-dropdown :trigger="['click']" placement="bottomLeft" slot="extra">
              <a class="ant-dropdown-link" href="#">
                <a-icon type="ellipsis" />
              </a>
              <a-menu slot="overlay">
                <a-menu-item>
                  <a href="javascript:;"></a>
                </a-menu-item>
              </a-menu>
            </a-dropdown>
            <a-row :gutter="68">
              <a-col :xs="24" :sm="24" :style="{ marginBottom: ' 24px'}">
                <number-info :total="12321" :sub-total="17.1" status="up">
                  <span slot="subtitle">
                    <span>预警信息数</span>
                    <a-tooltip title="指标说明" slot="action">
                      <a-icon type="info-circle-o" :style="{ marginLeft: '8px' }" />
                    </a-tooltip>
                  </span>
                </number-info>
                <!-- miniChart -->
                <div>
                  <mini-smooth-area :style="{ height: '45px' }" :dataSource="searchUserData" :scale="searchUserScale" />
                </div>
              </a-col>
            </a-row>
            <div class="ant-table-wrapper">
              <a-table
                row-key="index"
                size="small"
                :columns="searchTableColumns"
                :dataSource="searchData"
                :pagination="{ pageSize: 5 }"
              >
                <span slot="range" slot-scope="text, record">
                  <trend :flag="record.status === 0 ? 'up' : 'down'">
                    {{ text }}%
                  </trend>
                </span>
              </a-table>
            </div>
          </a-card>
        </a-col>
      </a-row>
      <a-row :gutter="24" type="flex" :style="{ marginTop: '24px' }" v-if="false">
        <a-col :xl="24" :lg="24" :md="24" :sm="24" :xs="24">
          <a-card class="antd-pro-pages-dashboard-analysis-salesCard" :loading="loading" :bordered="false" title="销售额类别占比" :style="{ height: '100%' }">
            <div slot="extra" style="height: inherit;">
              <!-- style="bottom: 12px;display: inline-block;" -->
              <span class="dashboard-analysis-iconGroup" v-if="false">
                <a-dropdown :trigger="['click']" placement="bottomLeft">
                  <a-icon type="ellipsis" class="ant-dropdown-link" />
                  <a-menu slot="overlay">
                    <a-menu-item>
                      <a href="javascript:;">操作一</a>
                    </a-menu-item>
                    <a-menu-item>
                      <a href="javascript:;">操作二</a>
                    </a-menu-item>
                  </a-menu>
                </a-dropdown>
              </span>
              <div class="analysis-salesTypeRadio" v-if="false">
                <a-radio-group defaultValue="a">
                  <a-radio-button value="a">全部渠道</a-radio-button>
                  <a-radio-button value="b">线上</a-radio-button>
                  <a-radio-button value="c">门店</a-radio-button>
                </a-radio-group>
              </div>

            </div>
            <h4 style="padding-left: 20px;">销售额 2535</h4>
            <div>
              <!-- style="width: calc(100% - 240px);" -->
              <div>
                <v-chart :force-fit="true" :height="405" :data="pieData" :scale="pieScale">
                  <v-tooltip :showTitle="false" dataKey="item*percent" />
                  <v-axis />
                  <!-- position="right" :offsetX="-140" -->
                  <v-legend dataKey="item"/>
                  <v-pie position="percent" color="item" :vStyle="pieStyle" />
                  <v-coord type="theta" :radius="0.75" :innerRadius="0.6" />
                </v-chart>
              </div>

            </div>
          </a-card>
        </a-col>
      </a-row>
    </div>
    <!--三大card -->
    <a-card v-if="true" :loading="orderLoading" :bordered="false" :body-style="{padding: '0', marginBottom: '24px'}" title="订单">
      <div style="margin: 20px">
        <a-radio-group v-model="radio" :default-value="1" @change="changeFilter" button-style="solid">
          <a-radio-button :value="1" border>30天</a-radio-button>
          <a-radio-button :value="2" border>周</a-radio-button>
          <a-radio-button :value="3" border>月</a-radio-button>
          <a-radio-button :value="4" border v-if="false">年</a-radio-button>
        </a-radio-group>
      </div>
      <div class="salesCard">
        <a-row>
          <a-col :xl="24" :lg="24" :md="24" :sm="24" :xs="24">
            <bar :data="barData" title="金额" :scale="order_scale"/>
          </a-col>
        </a-row>
      </div>
    </a-card>
    <a-card v-if="true" :bordered="false" :body-style="{padding: '0', marginBottom: '24px'}" title="低库存商品">
      <a slot="extra" @click="toGoodsList">商品列表</a>
      <div class="salesCard">
        <a-row>
          <a-col :xl="24" :lg="24" :md="24" :sm="24" :xs="24">
            <s-table
              ref="table"
              size="small"
              :loading="goodsLoading"
              :columns="columns"
              :pageNum="pageNum"
              :data="loadData"
              :scroll="{ x: 1000 }"
              rowKey="id">

              <span slot="serial" slot-scope="text, record, index">{{ index + 1 }}</span>
              <span slot="img" slot-scope="img">
                <a-avatar
                  slot="img"
                  :src="img"
                  shape="square"
                />
              </span>
              <div slot="count" slot-scope="text" style="min-width: 120px; max-width: 120px; text-align: right;">
                {{ text | numberFormat }}
              </div>

              <div slot="price_render" slot-scope="text" style="width: 150px; text-align: right;">
                {{ (text / 100).toFixed(2) | numberFormat }}
              </div>
            </s-table>
          </a-col>
        </a-row>
      </div>
    </a-card>
  </div>
</template>

<script>
import moment from 'moment'
import {
  ChartCard,
  MiniArea,
  MiniBar,
  MiniProgress,
  RankList,
  Bar,
  Trend,
  NumberInfo,
  MiniSmoothArea,
  STable
} from '@/components'
import { baseMixin } from '@/store/app-mixin'
import { dashboard_statistic_list, order_statistic_list } from '@/api/dashboard'
import { listGoods } from '@/api/applet_goods'
const searchUserData = []
for (let i = 0; i < 7; i++) {
  searchUserData.push({
    x: moment().add(i, 'days').format('YYYY-MM-DD'),
    y: Math.ceil(Math.random() * 10)
  })
}
const searchUserScale = [
  {
    dataKey: 'x',
    alias: '时间'
  },
  {
    dataKey: 'y',
    alias: '数量',
    color: '#F4664A',
    min: 0,
    max: 10
  }]

const searchTableColumns = [
  {
    dataIndex: 'index',
    title: '排名',
    width: 90
  },
  {
    dataIndex: 'keyword',
    title: '搜索关键词'
  },
  {
    dataIndex: 'count',
    title: '用户数'
  },
  {
    dataIndex: 'range',
    title: '周涨幅',
    align: 'right',
    sorter: (a, b) => a.range - b.range,
    scopedSlots: { customRender: 'range' }
  }
]
const searchData = []
for (let i = 0; i < 50; i += 1) {
  searchData.push({
    index: i + 1,
    keyword: `搜索关键词-${i}`,
    count: Math.floor(Math.random() * 1000),
    range: Math.floor(Math.random() * 100),
    status: Math.floor((Math.random() * 10) % 2)
  })
}

const DataSet = require('@antv/data-set')

const sourceData = [
  { item: '家用电器', count: 32.2 },
  { item: '食用酒水', count: 21 },
  { item: '个护健康', count: 17 },
  { item: '服饰箱包', count: 13 },
  { item: '母婴产品', count: 9 },
  { item: '其他', count: 7.8 }
]

const pieScale = [{
  dataKey: 'percent',
  min: 0,
  formatter: '.0%'
}]

const dv = new DataSet.View().source(sourceData)
dv.transform({
  type: 'percent',
  field: 'count',
  dimension: 'item',
  as: 'percent'
})
const pieData = dv.rows

export default {
  name: 'Analysis',
  mixins: [baseMixin],
  components: {
    ChartCard,
    MiniArea,
    MiniBar,
    MiniProgress,
    RankList,
    Bar,
    Trend,
    NumberInfo,
    MiniSmoothArea,
    STable
  },
  data () {
    return {
      radio: 1,
      loading: true,
      orderLoading: true,
      goodsLoading: false,
      dataAll: {},
      // 搜索用户数
      searchUserData,
      searchUserScale,
      searchTableColumns,
      searchData,
      barData: [],
      rankList: [],
      barData2: [],
      rankList2: [],
      barData3: [],
      gate_sum: 0,
      order_sum: 0,
      order_count: 10,
      order_scale: [
        {
          dataKey: 'x',
          alias: '时间'
        },
        {
          dataKey: 'y',
          alias: '金额'
          }
       ],
      gate_scale: [
        {
          dataKey: 'x',
          alias: '时间'
        },
        {
          dataKey: 'y',
          alias: '金额'
        }
      ],
      bonded_scale: [
        {
          dataKey: 'x',
          alias: '品类'
        },
        {
          dataKey: 'y',
          alias: '数量'
        }
      ],
      //
      pieScale,
      pieData,
      sourceData,
      pieStyle: {
        stroke: '#fff',
        lineWidth: 1
      },
      pageNum: 1,
      columns: [
        {
          title: '序号',
          width: '100px',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '商品名称',
          dataIndex: 'name',
          width: '150px',
          ellipsis: true
        },
        {
          title: '图片',
          dataIndex: 'picture',
          width: '100px',
          scopedSlots: { customRender: 'img' }
        },
        {
          title: '微信展示名称',
          dataIndex: 'brief',
          width: '200px',
          ellipsis: true
        },
        {
          title: '供应商',
          dataIndex: 'supplier',
          width: '150px',
          ellipsis: true
        },
        {
          title: '条形码',
          dataIndex: 'barcode',
          width: '150px',
          ellipsis: true
        },
        {
          title: '品牌',
          dataIndex: 'brand',
          width: '150px',
          ellipsis: true
        },
        {
          title: '数量',
          dataIndex: 'count',
          align: 'right',
          width: 120,
          scopedSlots: { customRender: 'count' }
        },
        {
          title: '销售量',
          dataIndex: 'sale_count',
          align: 'right',
          width: 120,
          scopedSlots: { customRender: 'count' }
        },
        {
          title: '现价',
          dataIndex: 'price',
          align: 'right',
          width: 150,
          scopedSlots: { customRender: 'price_render' }
        }
      ],
      queryParam: {
        low_inventory: 1
      },
      loadData: parameter => {
        return listGoods(Object.assign(parameter, this.queryParam))
          .then(res => {
            console.log(res.data)
            return res.data
          }).finally(() => {
            this.goodsLoading = false
          })
      }
    }
  },
  created () {
    this.getData()
    this.getOrderList()
  },
  methods: {
    getData () {
      this.loading = true
      dashboard_statistic_list().then((res) => {
        if (res.code === 1000) {
          this.dataAll = res.data
        }
      }).finally(() => {
        this.loading = false
      })
    },
    getOrderList () {
      this.orderLoading = true
      order_statistic_list({ order_filter: this.radio }).then((res) => {
        if (res.code === 1000) {
          // this.dataAll = res.data
          if (res.data.order_list) {
            const data = res.data.order_list.map(item => {
              // let time = '' + item.time
              // if (this.radio === '1') {
              //   if (time.length > 8 && time.includes('-')) {
              //     time = time.substring(5)
              //   }
              // } else if (this.radio === '2') {
              //   // time = time
              // } else if (this.radio === '3') {
              //   // time = time + '月'
              // }
              return {
                x: item.time,
                y: (item.paid / 100),
                name: item.time
              }
            })
            const dv = new DataSet.View().source(data)
            dv.transform({
              type: 'fold',
              fields: ['time'],
              key: '时间',
              value: '订单金额'
            })
            this.barData = dv.rows
          }
        }
      }).finally(() => {
        this.orderLoading = false
      })
    },
    QOQ (value1, value2) {
      if (value1 === undefined || value2 === undefined || (Number(value1) === 0 && Number(value2) === 0)) {
        return 0
      }
      if (Number(value1) === 0) {
        return 0
      } else {
        if (Number(value2) === 0) {
          return 0
        }
        return ((value1 - value2) / value2 * 100).toFixed(2)
      }
    },
    changeFilter (value) {
      this.getOrderList()
    },
    getTagColor (edited) {
      return edited ? 'green' : 'red'
    },
    toGoodsList () {
      this.$router.push({ path: '/applet_goods/goods_list' })
    }
  }
}
</script>

<style lang="less" scoped>
.extra-wrapper {
  line-height: 55px;
  padding-right: 24px;

  .extra-item {
    display: inline-block;
    margin-right: 24px;

    a {
      margin-left: 24px;
    }
  }
}

.antd-pro-pages-dashboard-analysis-twoColLayout {
  position: relative;
  display: flex;
  display: block;
  flex-flow: row wrap;
}

.antd-pro-pages-dashboard-analysis-salesCard {
  height: calc(100% - 24px);
  /deep/ .ant-card-head {
    position: relative;
  }
}

.dashboard-analysis-iconGroup {
  i {
    margin-left: 16px;
    color: rgba(0,0,0,.45);
    cursor: pointer;
    transition: color .32s;
    color: black;
  }
}
.analysis-salesTypeRadio {
  position: absolute;
  right: 54px;
  bottom: 12px;
}

</style>
